import React from 'react';
import { IntlProvider } from 'react-intl';
import enUS from 'antd/lib/locale/en_US';
import csCZ from 'antd/lib/locale/cs_CZ';
import { ConfigProvider } from 'antd';
import { ApolloProvider } from '@apollo/client';
import messages from './translations';
import { RootRouter } from './RootRouter';
import { apolloClient } from './services/apolloClient';
import './style.css';
import { useLanguageContext } from './contexts/language/languageContext';

export const App: React.FC = () => {
  const { userLanguage } = useLanguageContext();

  return (
    <div className="App">
      <IntlProvider locale={userLanguage} messages={messages[userLanguage]}>
        <ApolloProvider client={apolloClient}>
          <ConfigProvider locale={userLanguage === 'en' ? enUS : csCZ}>
            <RootRouter />
          </ConfigProvider>
        </ApolloProvider>
      </IntlProvider>
    </div>
  );
};
