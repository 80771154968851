/* eslint-disable camelcase */
import { InventoryMeter } from '@enmon/commons/lib/tools';
// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';
import type { InventoryLocationBuilding } from '../../../types/inventory/inventoryBuilding';

export const GET_TENANTS_AND_BUILDINGS = gql`
  query filterData($id: ID!) {
    # Tennants for TenantMultiselect tags
    # limit is overriden due to frontend groupBy limitation
    # sorted by the tenant name
    inventoryMeters(
      pagination: { limit: 1000 }
      sort: ["tenant:asc"]
      filters: { inventory_location: { id: { eq: 58 } }, tenant: { notNull: true } }
    ) {
      data {
        attributes {
          tenant
        }
      }
    }

    #Buildings count for MeterCard
    inventoryLocations(filters: { id: { eq: $id } }) {
      data {
        attributes {
          inventory_location_buildings(sort: ["name:asc"]) {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export interface FilterVariables {
  id: string | number;
}

export interface FilterData {
  inventoryLocations: {
    data: Array<{
      inventory_location_buildings: Array<Pick<InventoryLocationBuilding, 'name' | 'id'>>;
    }>;
  };
  inventoryMeters: {
    data: Array<Pick<InventoryMeter, 'tenant'>>;
  };
}
