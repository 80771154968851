/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
import gql from 'graphql-tag';
import { InventoryMeter } from '@enmon/commons/lib/tools';
import { InventoryMeterType } from '../../../enums/inventoryMeterType';
import type { InventoryLocationBuilding } from '../../../types/inventory/inventoryBuilding';
import type { InventoryLocation } from '../../../types/inventory/inventoryLocation';

export const GET_METER_TABLE_DATA = gql`
  query getInventoryMetersTableData(
    $id: ID!
    $limit: Int
    $locationBuildings: [String]
    $sort: [String]
    $start: Int
    $tenants: [String]
    $meterTypes: [String]
  ) {
    # Meters data used in table and MeterCard
    inventoryMeters(
      pagination: { limit: $limit, start: $start }
      sort: $sort
      filters: {
        inventory_location: { id: { eq: $id } }
        inventory_location_building: { name: { in: $locationBuildings } }
        meter_type: { in: $meterTypes }
        tenant: { in: $tenants }
      }
    ) {
      meta {
        pagination {
          total
        }
      }
      data {
        id
        attributes {
          # table columns
          meter_type
          serial_number
          monitored_entity
          inventory_location_building {
            data {
              id
              attributes {
                name
              }
            }
          }
          accessibility

          #heatmap
          inventory_meter_api_key {
            data {
              attributes {
                api_key
              }
            }
          }
          submeter
          new_meter
          lorawan_rssi
          lat
          lng
          owner
          tenant
          manufactured
          calibrated
          backend_gateway_meter_id
          inventory_location_building {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export interface InventoryLocationVariables {
  id: string | number;
  limit: number;
  locationBuildings?: InventoryLocationBuilding[];
  meterTypes?: InventoryMeterType[];
  sort: string[];
  start: number;
  tenants?: string[];
}

export interface InventoryMetersTableResponse {
  inventoryMeters: {
    meta: {
      pagination: {
        total: number;
      };
    };
    data: Array<
      Pick<
        InventoryMeter,
        | 'id'
        | 'meter_type'
        | 'serial_number'
        | 'monitored_entity'
        | 'accessibility'
        | 'submeter'
        | 'new_meter'
        | 'lorawan_rssi'
        | 'lat'
        | 'lng'
        | 'owner'
        | 'tenant'
        | 'manufactured'
        | 'calibrated'
        | 'inventory_meter_api_key'
        | 'backend_gateway_meter_id'
      > & {
        inventory_location_building: Pick<InventoryLocation, 'name'>;
      }
    >;
  };
}
