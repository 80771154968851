import { useQuery } from '@apollo/client';
import { InventoryMeterType } from '@enmon/commons/lib/tools/inventory_meter';
import { deserialize } from '@enmon/commons';
import {
  GET_INVENTORY_METER_TYPE_DETAILS,
  InventoryMeterTypeDetailsData,
  InventoryMeterTypeDetailsVariables,
} from '../queries/getInventoryMeterTypeDetails';
import { InventoryMeterData } from '../queries/getInventoryMeterData';
import { InventoryMeterDetails } from '../../../../../types/inventory/inventoryMeterDetails';

interface UseMeterTypeDetailsShape {
  isLoading: boolean;
  data: InventoryMeterDetails | undefined;
}

export function useMeterTypeDetails(
  inventoryMeter: InventoryMeterData | Record<string, never>,
): UseMeterTypeDetailsShape {
  const { data, loading: isLoading } = useQuery<InventoryMeterTypeDetailsData, InventoryMeterTypeDetailsVariables>(
    GET_INVENTORY_METER_TYPE_DETAILS[inventoryMeter.meter_type ?? InventoryMeterType.Other],
    {
      onError(error) {
        console.error(error);
      },
      variables: {
        inventoryMeterId: inventoryMeter.id,
      },
      skip: !inventoryMeter.meter_type,
    },
  );

  const typeDetailsData = deserialize<InventoryMeterTypeDetailsData>(data ?? [])?.typeDetails?.data?.[0];

  return { data: typeDetailsData, isLoading };
}
